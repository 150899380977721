<template>
  <div>
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-60 md-size-50"
      >
       
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-40 md-size-50"
      >
        
      </div>
    </div>
    <md-table
      v-if="data.data"
      v-model="data.data"
      :table-header-color="tableHeaderColor"
    >
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Type">{{item.type }}</md-table-cell>
        <md-table-cell md-label="Plate">{{ item.plate }}</md-table-cell>
        <md-table-cell md-label="Driver">{{ item.driver }}</md-table-cell>
        <md-table-cell md-label="Permits">{{item.permission}}</md-table-cell>    
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
export default {
  name: "vehicle-crud",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selected: [],
      data: {},
      filter: "",
    };
  },
  methods: {
    getVehicles(){

        this.data = {data: [
          {
              type: "Truck",
              driver:"John Travoltres",
              plate: "ATUJ20",
              permission: "UP TO DATE"
          },
          {
              type: "Truck",
              driver:"Bob Contructor",
              plate: "AHPB27",
              permission: "EXPIRED"
          },
           {
              type: "SEDAN",
              driver:"Miguel Jordan",
              plate: "BTCB23",
              permission: "UP TO DATE"
          },
          {
              type: "MINIVAN",
              driver:"Ruben Maister",
              plate: "JTOP98",
              permission: "UP TO DATE"
          },
          {
              type: "CAEX TRUCK",
              driver:"Mery Opins",
              plate: "BNTO57",
              permission: "EXPIRED"
          }
        ]
        }

        // this.$store
        //   .dispatch("vehicle/getFakeVehicles")
        //   .then((data) => {
        //   this.data = data;
        // })
        // .catch((error) => {
        //   console.log(error);
        // });
    }

  },
  mounted() {
    this.getVehicles();
  },
};
</script>